import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import OgMeta from '../components/ogMeta';

export default function NotFound() {
    return(
        <div className='flex flex-col w-screen h-screen lg:gap-4 overflow-x-hidden scroll-smooth'>
            <OgMeta title='Página inicial' description='Desde que abrimos, o escritório W68 ISENÇÕES estabelece um compromisso sério com a satisfação do cliente. Com profissionais especializados no processo de Isenções de Impostos para pessoas com necessidades especiais, proporcionamos ao requerente uma experiência ímpar, ofertando nossos serviços em todas as esteiras de trabalho com padrão de excelência. Para obter mais informações sobre produtos e serviços, entre em contato hoje mesmo e sinta a diferença.' />
            <Navbar />
            <div className='flex flex-col w-screen h-screen justify-center items-center'>
                <span className='text-[1.5rem] lg:text-[2.5rem] font-bold'>Página não encontrada</span>
                <span className='text-[1.5rem] text-center'>Voltar para <a className='flex justify-center items-center p-1 rounded bg-blue-400 transition hover:bg-blue-600 text-white' href="/">página inicial</a></span>
            </div>
            <Footer />
        </div>
    );
}