import React from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import OgMeta from '../components/ogMeta';

export default function Home() {
    return(
        <div className='flex flex-col w-screen h-screen lg:gap-4 overflow-x-hidden scroll-smooth'>
            <OgMeta title='Página inicial' description='Desde que abrimos, o escritório W68 ISENÇÕES estabelece um compromisso sério com a satisfação do cliente. Com profissionais especializados no processo de Isenções de Impostos para pessoas com necessidades especiais, proporcionamos ao requerente uma experiência ímpar, ofertando nossos serviços em todas as esteiras de trabalho com padrão de excelência. Para obter mais informações sobre produtos e serviços, entre em contato hoje mesmo e sinta a diferença.' />
            <Navbar />
            <div id='about' className='flex w-screen h-1/3 lg:h-[55%] justify-center'>
                <div className='flex flex-col w-full lg:w-[95%] h-full items-center bg-banner bg-no-repeat bg-cover bg-center lg:rounded-[2rem]'>
                    <div className='flex flex-col w-full h-full justify-center items-center text-white gap-7 bg-black/35 lg:rounded-[2rem] p-24'>
                        <div className='flex justify-center items-center'>
                            <img className='w-[30%] lg:w-[20%] ' src='icon-512x512.png' alt='Logotipo' />
                            <span className='text-[1rem] md:text-[3rem] lg:text-[5rem] font-bold'>W68 Isenções</span>
                        </div>
                        <span className='text-[1rem] font-bold text-center hidden lg:text-xl lg:block'>Comprometimento com a qualidade, confiança e preço que você merece.</span>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-center items-center w-full gap-7'>
                <div id='services' className='flex flex-col justify-center items-center'>
                    <span className='lg:text-[2.5rem] font-bold'>Sobre nós</span>
                    <span className='text-xl p-5 lg:w-[85%]'>
                        Desde que abrimos, o escritório <b>W68 ISENÇÕES</b> estabelece um compromisso sério com a satisfação do cliente. 
                        Com profissionais especializados no processo de Isenções de Impostos para pessoas com necessidades especiais, 
                        proporcionamos ao requerente uma experiência ímpar, ofertando nossos serviços em todas as esteiras de trabalho com padrão de excelência. 
                        Para obter mais informações sobre produtos e serviços, entre em contato hoje mesmo e sinta a diferença.
                    </span>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <span className='lg:text-[2.5rem] font-bold'>Você conhece os seus direitos?</span>
                    <span className='lg:text-[1.5rem] font-bold'>IPVA nunca mais!</span>
                    <span className='text-xl p-4 lg:w-[85%]'>
                    Ajudar nossos clientes em cada etapa do seu processo de isenções é a nossa missão e compromisso, 
                    fornecendo serviços úteis e atendendo a todas as suas necessidades observando todos os aspectos legais de um processo de isenções de impostos. 
                    Leia as informações a seguir para conhecer melhor nossos serviços. Fazemos o trâmite operacional e administrativo de isenções de tributos (<b>IPI-ICMS-IPVA</b>) para pessoas com deficiência física, 
                    Autista, deficiente intelectual.
                    </span>
                    <div className='flex flex-col justify-center items-center'>
                        <ul className='list-disc w-[65%]'>
                            <li>Habilitação especial (<b>Adição CNH</b>)</li>
                            <li>Isenção de IPI</li>
                            <li>Isenção ICMS</li>
                            <li>Isenção IPVA (<b>Veículo 0km e usado</b>)</li>
                            <li>Liberação de Rodízio Municipal para requerentes com lesão de membro inferior (<b>Decreto de 16 de janeiro 2019</b>)</li>
                            <li>Cartão Defis - Cartão Idoso</li>
                            <li>Emplacamento e documentação de veículo 0km (<b>CRV</b>/<b>CRLV</b>).</li>
                        </ul>
                    </div>
                </div>
                <div id='contact' className='flex flex-col justify-center items-center'>
                    <span className='lg:text-[1.5rem] font-bold'>Horário de atendimento</span>
                    <span className='text-xl p-4 lg:w-[85%] text-center'>Segunda à sexta <b>9:00</b> - <b>18:00</b></span>
                </div>
                <div className='flex flex-col justify-center items-center'>
                    <span className='lg:text-[2.5rem] font-bold'>Contato</span>
                    <div className='flex flex-col justify-center items-center'>
                        <img className='rounded-[100%] w-48' src='icon-512x512.png' alt='William Rone Ferreira' />
                    </div>
                    <div className='flex gap-2'>
                        <a className='w-7' href='https://wa.link/rgbdfb'><img src='social/whatsapp.png' alt='Whatsapp' /></a>
                        <a className='w-7' href='https://br.linkedin.com/in/william-rone-4a207b27'><img src='social/linkedin.png' alt='Linkedin' /></a>
                        <a className='w-7' href='https://facebook.com/people/williamrone1968/100063640775466'><img src='social/facebook.png' alt='Facebook' /></a>
                        <a className='w-7' href='https://www.instagram.com/williamrone1968/'><img src='social/instagram.png' alt='Instagram' /></a>
                        <a className='w-7' href='mailto:contato@w68isencoes.com'><img src='social/email.png' alt='Email' /></a>
                    </div>
                </div>
                <div className='flex w-full flex-col justify-center items-center gap-4'>
                    <span className='lg:text-[2.5rem] font-bold'>Parceiros</span>
                    <div className='flex w-full flex-col justify-center lg:flex-row lg:justify-around items-center gap-4 lg:gap-0'>
                        <a className='flex flex-col justify-center items-center w-44' href='https://www.protheuscorretora.com.br' target='blank'>
                            <img className='drop-shadow-[0_0_1px_rgba(0,0,255,0.8)]' src='partners/protheus.webp' alt='Protheus' />
                            <span className='text-sm font-bold text-nowrap'>Planos de Saúde e Seguro Auto</span>
                        </a>
                        <a className='w-44' href='https://alliance-despachante.webnode.page' target='blank'><img src='partners/alliance.png' alt='Alliance' /></a>
                        <a className='flex flex-col justify-center items-center w-44' href='https://www.instagram.com/lemansgaragem' target='blank'>
                            <img src='partners/lemans.png' alt='Le Mans' />
                            <span className='text-sm font-bold'>Estética automotiva</span>
                        </a>
                        <a className='flex flex-col justify-center items-center w-44' href='https://www.instagram.com/graficatriskel' target='blank'>
                            <img src='partners/triskel.png' alt='Triskel' />
                            <span className='text-sm font-bold'>Comunicação visual</span>
                        </a>
                    </div>
                    </div>
            </div>
            <Footer />
        </div>
    );
}