import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './views/Home.view';
import NotFound from './views/NotFound.view';

export default function MainRoutes() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    );
}