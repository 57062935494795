import React, { useEffect, useState } from 'react';

export default function Navbar() {
    const [backdropBlur, setBackdropBlur] = useState('backdrop-blur-none bg-slate-100');
    useEffect(() => {
        const rootDiv = document.querySelector('#root > div');
        const scrollHandler = () => {
            if(rootDiv.scrollTop > 50) {
                setBackdropBlur('backdrop-blur-lg bg-slate-100/30 border-b-2');
            } else {
                setBackdropBlur('backdrop-blur-none bg-slate-100');
            }
        }

        rootDiv?.addEventListener('scroll', scrollHandler);

        return () => {
            rootDiv?.removeEventListener('scroll', scrollHandler);
        }
    }, [ backdropBlur ]);
    return(
        <div className={`flex w-full sticky top-[-0.5px] left-0 z-10 p-3 border-blue-400 text-blue-400 transition ${backdropBlur}`}>
            <img className='w-16' src="icon-512x512.png" alt='Logotipo' />
            <div className='flex w-full justify-end items-center gap-3'>
                <a href="/#about">Sobre</a>
                <a href="/#services">Serviços</a>
                <a href="/#contact">Contato</a>
            </div>
        </div>
    );
}